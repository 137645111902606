var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fcoin_block"},[_c('div',{staticClass:"fcoin_inner"},[(!_vm.fcoin_loading)?_c('div',[_c('div',{staticClass:"title_row"},[_c('div',{staticClass:"service_title"},[_vm._v("FCoin")]),_c('router-link',{attrs:{"to":"/fcoin-shop","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var href = ref.href;
return [_c('a',{staticClass:"show_all icon_arrowStroke custom_a",attrs:{"href":href},on:{"click":navigate}})]}}],null,false,2984203261)})],1),_c('div',{staticClass:"coin_quantity"},[_vm._v(" "+_vm._s(_vm.fcoin_dashboard.balance)),_c('span',[_vm._v("FCoins")])]),_c('div',{staticClass:"field_row"},[_c('div',[_c('div',{staticClass:"item_inner"},[_c('span',[_vm._v(_vm._s(_vm.fcoin_dashboard.referrals))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Referal"))+" ")])])]),_c('div',[_c('div',{staticClass:"item_inner"},[_c('span',[_vm._v(_vm._s(_vm.fcoin_dashboard.exchanged))]),_c('span',[_vm._v(_vm._s(_vm.$t("Exchanged")))])])])]),(_vm.fcoin_dashboard.product)?_c('div',{staticClass:"product_block"},[_c('div',{staticClass:"image_block"},[_c('img',{attrs:{"src":_vm.fcoin_dashboard.product.image_path,"alt":"","title":""}})]),_c('div',{staticClass:"product_info"},[_c('div',{staticClass:"title_row"},[_c('div',{staticClass:"product_title"},[_vm._v(" "+_vm._s(_vm.fcoin_dashboard.product.name)+" ")]),_c('router-link',{attrs:{"to":"/fcoin-shop","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var href = ref.href;
return [_c('a',{staticClass:"icon_right product_show custom_a",attrs:{"href":href},on:{"click":navigate}})]}}],null,false,1637693146)})],1),_c('div',{staticClass:"change_coins"},[_c('div',{staticClass:"nearest_gift"},[_vm._v(" "+_vm._s(_vm.fcoin_dashboard.balance)+" "),_c('span',[_vm._v("/ "+_vm._s(_vm.fcoin_dashboard.product.price))])]),_c('div',{staticClass:"gift_line"},[_c('span',{style:({
                  width: _vm.getProductWidth(_vm.fcoin_dashboard.product.price),
                })})])])])]):_vm._e(),_c('div',{staticClass:"fcoin_bottom"},[_c('div',{staticClass:"bottom_title"},[_vm._v(_vm._s(_vm.$t("Invite friend")))]),_c('div',{staticClass:"copy_from"},[_c('span',{staticClass:"ref_link"},[_vm._v(_vm._s(_vm.fcoin_dashboard.referral_url))]),_c('button',{staticClass:"copy_btn",class:_vm.icon,on:{"click":function($event){return _vm.copyCode(_vm.fcoin_dashboard.referral_url)}}})])])]):_c('div',[_c('v-skeleton-loader',_vm._b({attrs:{"type":"heading, image, actions, image"}},'v-skeleton-loader',_vm.attrs,false))],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }